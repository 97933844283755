import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

export default function useScenariosList() {
  const refScenarioListTable = ref(null)

  // Table Handlers
  const perPage = ref(10)
  const totalScenarios = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refScenarioListTable.value ? refScenarioListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalScenarios.value,
    }
  })

  const refetchData = () => {
    refScenarioListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchStats = (ctx, callback) => {
    store
      .dispatch('app-scenario/fetchStats', {
      })
      .then(response => {
        if (response) {
          callback(response)
        }
        return response
      })
  }

  const fetchReport = (ctx, callback) => {
    store
      .dispatch('app-scenario/fetchReport', {
      })
      .then(response => {
        if (response) {
          callback(response)
        }
        return response
      })
  }

  const fetchScenarios = (ctx, callback) => {
    store
      .dispatch('app-scenario/fetchScenarios', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const scenarios = response.data
        if (callback) {
          callback(scenarios)
        }
        totalScenarios.value = scenarios.length
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveScenarioRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveScenarioRoleIcon = role => {
    if (role === 'subscriber') return 'ScenarioIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'ScenarioIcon'
  }

  const resolveScenarioStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchScenarios,
    fetchStats,
    perPage,
    currentPage,
    totalScenarios,
    fetchReport,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refScenarioListTable,

    resolveScenarioRoleVariant,
    resolveScenarioRoleIcon,
    resolveScenarioStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}

import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchScenarios(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/scenarios/status', { params: queryParams })
          .then(response => {
            localStorage.setItem('scenarios', JSON.stringify(response.data))
            resolve(response)
            return response.data
          })
          .catch(error => reject(error))
      })
    },
    fetchStats(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/scenarios/stats', { params: queryParams })
          .then(response => {
            localStorage.setItem('stats', JSON.stringify(response.data))
            resolve(response.data)
            return response.data
          })
          .catch(error => reject(error))
      })
    },
    fetchReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/scenarios/report', { params: queryParams })
          .then(response => {
            localStorage.setItem('report', JSON.stringify(response.data))
            resolve(response.data)
            return response.data
          })
          .catch(error => reject(error))
      })
    },
    fetchScenario(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/scenarios/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addScenario(ctx, scenarioData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/scenario/scenarios', { scenario: scenarioData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

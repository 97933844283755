<template>
  <div>
    <b-card id="compose-mail">
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        layout="vertical"
        ref="actionWizard"
        hide-footer
        back-button-text="Previous"
        class="wizard-vertical mb-3 shadow-none"
        @on-complete="back"
      >
        <tab-content title="Overview">
          <b-row>
            <b-col
              cols="12"
            >
              <h4>
                Let's {{ task.fix }} at your {{ task.app_name }}
              </h4>
              <b-row>
                <b-col cols="12">
                  <p class="mb-0">
                    Complexity: <b-badge variant="light-warning">Medium</b-badge>
                  </p>
                  <p>
                    Estimated Time: 10mins
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
            </b-col>
          </b-row>
        </tab-content>

        <!-- account datails tab -->
        <tab-content title="Solution">
          <b-row>
            <b-col
              cols="12"
            >
              <h4 class="mb-0">
                Follow the steps below:
              </h4>
             <div class="fix-modal" v-html="task.action"></div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
            </b-col>
          </b-row>
        </tab-content>

        <!-- personal info tab -->
        <tab-content title="Communicate" v-if="0">
          <b-row>
            <b-col
              cols="4"
              class="mb-2"
            >
              <h4>
                Send the following text to your employees:
              </h4>
              <b-form>
                <!-- Field: Subject -->
                <div class="compose-mail-form-field">
                  <label for="email-subject">Subject: </label>
                  <b-form-input
                    id="email-subject"
                    v-model="composeData.subject"
                  />
                </div>

                <!-- Field: Message - Quill Editor -->
                <div class="message-editor">
                  <quill-editor
                    id="quil-content"
                    v-model="composeData.message"
                    :options="editorOption"
                  />
                  <div
                    id="quill-toolbar"
                    class="d-flex border-bottom-0"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold" />
                    <button class="ql-italic" />
                    <button class="ql-underline" />
                    <button class="ql-align" />
                    <button class="ql-link" />
                  </div>
                </div>
              </b-form>
              <div class="mt-1 float-right">
                <b-dropdown
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  split
                  text="Send"
                  variant="primary"
                  right
                >
                  <b-dropdown-item>
                    Schedule Send
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>
          </b-row>
        </tab-content>
      </form-wizard>
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import { FormWizard, TabContent } from 'vue-form-wizard'
import router from '@/router'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  onUnmounted,
} from '@vue/composition-api'
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BBadge,
  BForm,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import useScenariosList from './api/useScenariosList'
import scenarioStoreModule from './api/scenarioStoreModule'

export default {
  components: {
    BRow,
    BCol,
    FormWizard,
    TabContent,
    BCard,
    quillEditor,
    BFormInput,
    BDropdownItem,
    BDropdown,
    BForm,
    BBadge,
  },
  data() {
    return {
      showMsg: false,
      composeData: {
        subject: '',
        message: '',
      },
      stats: {},
    }
  },
  computed: {
    task() {
      if (this.stats.actions) {
        return this.stats.actions.filter(item => item.id === router.currentRoute.params.id)[0]
      }
      return {}
    },
  },
  mounted() {
    const stats = localStorage.getItem('stats')
    if (stats) {
      this.stats = JSON.parse(stats)
    }
    this.fetchStats(null, this.initActions)
  },
  methods: {
    initActions(stats) {
      this.stats = stats
      this.stats = JSON.parse(localStorage.getItem('stats'))
    },
    back() {
      return router.go(-1)
    },
  },
  setup() {
    const SCENARIO_APP_STORE_MODULE_NAME = 'app-scenario'

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Message',
    }
    // Register module
    if (!store.hasModule(SCENARIO_APP_STORE_MODULE_NAME)) store.registerModule(SCENARIO_APP_STORE_MODULE_NAME, scenarioStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SCENARIO_APP_STORE_MODULE_NAME)) store.unregisterModule(SCENARIO_APP_STORE_MODULE_NAME)
    })

    const {
      fetchStats,
    } = useScenariosList()

    return {
      editorOption,
      fetchStats,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/quill.scss';
@import "~@core/scss/base/pages/app-email.scss";
</style>

<style lang="scss" scoped>
form ::v-deep {

  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>
